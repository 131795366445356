import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

// 获取对象记录权限
export function getPermissionById(args) {
    return axios.post('objectdetail/getPermissionById', args)
}
// 从QuickBooks同步发票
export function syncInvoicesFromQB(data) {
    return axios.post('/quickbooks/syncInvoicesFromQB', data)
}
// 获取活动列表
export function GetActivityGetActivityList(data) {
    return axios.post('/activity/getActivityList', data)
}
//文件-附件添加
export function Bind(data) {
    return axios.post('/file/bind', data)
}
// //联系人角色 删除
export function deleteContact(data) {
    return axios.post('/contactRole/delete', data)
}
//获取活动快速新建页面信息
export function ActivityGetQuickActivityPage(data) {
    return axios.post('/activity/getQuickActivityPage', data)
}
//获取相关列表数据及按钮和字段信息
export function GetDetailRelatedItems(data) {
    return axios.post('/objectdetail/getDetailRelatedItems', data)
}
// 获取视图列表
export function GetViewList(data) {
    return axios.post('/view/list/getViewList', data)
}
// 详情删除接口
export function GetDelete(data) {
    return axios.post('/objectdetail/delete', data)
}
// 根据对象id获取图表中的仪表板列表
export function getDashboardListByObjId(data) {
    return axios.post('/dashboard/getDashboardListByObjId', data)
}
// 选项卡菜单页面根据对象id获取报表列表
export function getReportListByObjId(data) {
    return axios.post('/report/getReportListByObjId', data)
}
// 当前是否启用了quickbooks功能  查询QB权限
export function quickbookStart(data) {
    return axios.get('/quickbooks/getQBPermission', data)
}
// 回款同步到QuickBooks
export function cloudccPaymentToQBPayment(data) {
    return axios.post('/quickbooks/cloudccPaymentToQBPayment', data)
}
// 从QuickBooks同步回款
export function syncPaymentFromQB(data) {
    return axios.post('/quickbooks/syncPaymentFromQB', data)
}
// 删除记录
export function deleteBom(args) {
    return axios.post('objectdetail/delete', args)
}
// 同步至quickbooks
export function cloudccInvoicesToQBInvoices(data) {
    return axios.post('/quickbooks/cloudccInvoicesToQBInvoices', data)
}
// 获取资产BOM列表-高含宇
export function getAssetBOMList(args) {
    return axios.post('ManageAssetBOM/getAssetBOMList', args)
}
// 订单同步到QuickBooks
export function cloudccOrderToQBEstimate(data) {
    return axios.post('/quickbooks/cloudccOrderToQBEstimate', data)
}
// 获取资产BOM管理权限 --- 赵晨宇
export function isOpenAsset(args) {
    return axios.post('ManageAssetBOM/getManageAssetBOMPermission', args)
}
// 看板视图展示
export function GetDataHierarchyDisplay(data) {
    return axios.post('/dataHierarchy/display', data)
}
//判断订单是否为已开具发票状态
export function isAlreadyGenerated(data) {
    return axios.post('/generateInvoiceNew/isAlreadyGenerated', data)
}
// 判断全部订单是否为同一个客户
export function isSameCustomer(data) {
    return axios.post('/generateInvoiceNew/isSameCustomer', data)
}
//判断全部订单是否为激活状态
export function isActiveOrder(data) {
    return axios.post('/generateInvoiceNew/isActiveOrder', data)
}

// 邮件发送页接口
export function getEmailObjectGetEmailPageInfo(data) {
    return axios.post('/emailObject/getEmailPageInfo', data)
}
// 更改邮件追踪状态
export function getEmailObjectGetChangeEmailTrackFlago(data) {
    return axios.post('/emailObject/changeEmailTrackFlag', data)
}

// 查询邮件模板
export function getEmailObjectGetEmailTemplates(data) {
    return axios.post('/emailObject/getEmailTemplates', data)
}

// 单条邮件详情
export function getEmailObjectgetEmailDetailInfo(data) {
    return axios.post('/emailObject/getEmailDetailInfo', data)
}

// 相关记录下的邮件信息
export function getEmailObjectGetEmailRelateData(data) {
    return axios.post('/emailObject/getEmailRelateData', data)
}

// 根据模板id和记录id获取模板内容
export function getEmailObjectGetTemplateBody(data) {
    return axios.post('/emailObject/getTemplateBody', data)
}

// 查询邀约
export function getEmailObjectgetAppointments(data) {
    return axios.post('/emailObject/getAppointments', data)
}

// 发送电子邮件
export function getEmailObjectsendEmailObject(data) {
    return axios.post('/emailObject/sendEmailObject', data)
}

// 保存邮件模板
export function getEmailObjectsaveEmailTemplate(data) {
    return axios.post('/emailObject/saveEmailTemplate', data)
}

// 置顶模板
export function getEmailObjecttopEmailTemplate(data) {
    return axios.post('/emailObject/topEmailTemplate', data)
}

// 查询字段
export function getEmailObjectqueryFields(data) {
    return axios.post('/emailObject/queryFields', data)
}

// 插入字段
export function getEmailObjectmergeField(data) {
    return axios.post('/emailObject/mergeField', data)
}

// 插入字段
export function getObjectGetObjectList(data) {
    return axios.post('/object/getObjectList', data)
}

// 删除邮件
export function getEmailObjectDelete(data) {
    return axios.post('/emailObject/delete', data)
}

// 根据关键字搜索邮箱
export function getEmailObjectSearchEmail(data) {
    return axios.post('/emailObject/searchEmail', data)
}

// 保存邮件签名
export function getEmailObjectSaveSign(data) {
    return axios.post('/emailObject/saveSign', data)
}


// 查询邮件签名
export function getEmailObjectQuerySign(data) {
    return axios.post('/emailObject/querySign', data)
}
// 清空邮件
export function deleteEmail(data) {
    return axios.post('/emailObject/deleteEmail', data)
}

// 根据发票的id获取邮件所需信息
export function getMessageByNum(data) {
    return axios.post('/emailonlinepay/GetMessageByNum', data)
}

// 邮件已发送
export function emailSuccess(data) {
    return axios.post('/emailonlinepay/emailSuccess', data)
}
// 发短信
export function getTextMessageSendTextMessage(data) {
    return axios.post('/textMessage/sendTextMessage', data)
}
// 查询短信模板   
export function getTextMessageGetTextMessageTemplata(data) {
    return axios.post('/textMessage/getTextMessageTemplate', data)
}
// 获取邮件模板列表
export function getMergeMailList(data) {
    return axios.post('/mailMerge/getMergeMailList', data)
}
// 获取邮件合并页面信息
export function getMailMergePageInfo(data) {
    return axios.post('/mailMerge/getMailMergePageInfo', data)
}


//邮件发送页
export function getEmailPageInfo(data) {
    return axios.post('emailObject/getEmailPageInfo', data)
}

//获取邮件模板
export function getEmailTemplate(onlyShowMyTemplate, searchWord) {
    return axios.post('emailObject/getEmailTemplates', { onlyShowMyTemplate: onlyShowMyTemplate, searchWord: searchWord })
}

//置顶模板
export function toTopTemplate(options) {
    return axios.post('emailObject/topEmailTemplate', options)
}

//保存邮件模板
export function saveEmailTemplate(options) {
    return axios.post('emailObject/saveEmailTemplate', options)
}
//发送邮件
export function sendemail(data) {
    return axios.post('emailObject/sendEmailObject', data)
}

//获取对象列表
export function getobjectlist() {
    return axios.post('object/getAllObjectList')
}

//获取字段
export function getsize(data) {
    return axios.post('emailObject/queryFields', data)
}

//插入字段
export function insertFields(data) {
    return axios.post('emailObject/mergeField', data)
}

//收件箱
export function inbox(data) {
    return axios.post('emailObject/queryEmailList', data)
}

//邮件详情
export function emaildetail(data) {
    return axios.post('emailObject/detail', data)
}

//更改邮件状态
export function changeread(data) {
    return axios.post('emailObject/changeRead', data)
}

//删除邮件
export function deleteemail(data) {
    return axios.post('emailObject/delete', data)
}
//根据模板id和记录获取模板内容获取
export function getTemplateSubject(data) {
    return axios.post('emailObject/getTemplateBody', data)
}

//获取收件人信息
export function getReceivePeo(data) {
    return axios.post('emailObject/getShouJianRenData', data)
}

//搜索收件人
export function seachemail(data) {
    return axios.post('emailObject/searchEmail', data)
}

//上传文件
export function uploadfile(data) {
    return axios.post('file/upload', data)
}

// 查询邮件签名
export function queryEmailSign(data) {
    return axios.post('/emailObject/querySign', data)
}

// 保存邮件签名
export function saveEmailSign(data) {
    return axios.post('/emailObject/saveSign', data)
}


//更改邮件关联记录
export function changerelateid(data) {
    return axios.post('emailObject/changeRelateid', data)
}

//删除邮件关联记录
export function deleterelateid(data) {
    return axios.post('emailObject/deleteRelateid', data)
}

//获取邮件数量
export function getemailnum(data) {
    return axios.post('emailObject/queryEmailCount', data)
}

// -----------------------企业微信联系人转联系人或潜在客户--------------------------
export function epWechatToContactAndLead(params) {
    return axios.post('/epwechatsynccontact/epWechatToContactAndLead', params)
}
//获取管理合作伙伴的权限
export function getPartnerGetPartnerPermission(data) {
    return axios.post('/partner/getPartnerPermission', data)
}
//客户下启用禁用合作伙伴
export function getPartnerPartnerEnableAccount(data) {
    return axios.post('/partner/partnerEnableAccount', data)
}
//联系人下启用禁用合作伙伴
export function getPartnerPartnerEnableContact(data) {
    return axios.post('/partner/partnerEnableContact', data)
}
//获取所有能登录的伙伴云链接
export function getPartnerQueryAllPartnerByLogin(data) {
    return axios.post('/partner/queryAllPartnerByLogin', data)
}

//查看翻译
export function findArticleIdTranslate(data) {
    return axios.post("/KnowledgeArticle/findArticleIdTranslate", data);
}

//归档
export function articlePigeonhole(data) {
    return axios.post("/KnowledgeArticle/articlePigeonhole", data);
}

//删除知识文章
export function removeArticle(data) {
    return axios.post("/knowledgeArticleOperate/removeArticle", data);
}

// 根据语言和状态获取知识文章
export function findConditionKnowledgeArticle(data) {
    return axios.post("/KnowledgeArticle/findConditionKnowledgeArticle", data);
}

// 查询语言接口
export function queryLanguage(data) {
    return axios.post("/knowledgeTranslate/queryLanguage", data);
}

// 判断是否是作为主版本发布的条件
export function ismasterVersion(data) {
    return axios.post("/knowledge/ismasterVersion", data);
}

// 发布文章接口
export function publishArticle(data) {
    return axios.post("/knowledge/publishArticle", data);
}

// 指派文章接口
export function designate(data) {
    return axios.post("/knowledgeDesignate/designate", data);
}

// 提交翻译校验
export function designateCheckout(data) {
    return axios.post("/knowledgeTranslate/designateCheckout", data);
}

// 提交翻译
export function translate(data) {
    return axios.post("/knowledgeTranslate/translate", data);
}

// 删除发布状态为草稿的知识文章
export function removeDraft(data) {
    return axios.post("/knowledgeArticleOperate/removeDraft", data);
}

// 恢复发布状态为已归档的知识文章
export function recoverArchived(data) {
    return axios.post("/knowledgeArticleOperate/recoverArchived", data);
}
// 作为草稿编辑
export function asDraftEdit(data) {
    return axios.post("/knowledgeArticleOperate/asDraftEdit", data);
}
// 看板视图展示
export function GetViewKanbanDisplay(data) {
    return axios.post('/viewKanban/display', data)
}

// 知识文章 版本列表
export function queryArticleVersionList(data) {
    return axios.post("/knowledgeArticleOperate/queryArticleVersionList", data);
}

// 知识文章下的个案相关列表
export function findArticleAndIndividualCase(data) {
    return axios.post('/KnowledgeArticle/findArticleAndIndividualCase', data)
}

// 全局搜索高级筛选
export function getGlobalSearchSingleResult(data) {
    return axios.post("/KnowledgeArticle/getGlobalSearchSingleResult", data);
}
// 生成订单按钮权限
export function generateOrder(data) {
    return axios.post('/transaction/generateOrder', data)
}

// 生成合同按钮权限
export function generateContract(data) {
    return axios.post('/transaction/generateContract', data)
}

// 生成发票按钮权限
export function generateInvoice(data) {
    return axios.post('/transaction/generateInvoice', data)
}
// 查询发票在线支付配置
export function getEmailOnlinePaySetUp(v) {
    return axios.get('/emailonlinepaysetup/getEmailOnlinePaySetUp', v)
}
// 同步报价行至业务机会
export function syncQuoteToOpportunity(data) {
    return axios.post('/cpq/quote/syncQuoteToOpportunity', data)
}
// 获取发票列表
export function showInvoices(data) {
    return axios.post('/transaction/showInvoices', data)
}

// 知识文章批量归档
export function batchArticlePigeonhole(data) {
    return axios.post('/KnowledgeArticle/batchArticlePigeonhole', data)
}

// 批量删除 知识文章
export function batchRemoveArticle(data) {
    return axios.post('/knowledgeArticleOperate/batchRemoveArticle', data)
}

// 批量删除发布状态为草稿的知识文章
export function batchRemoveDraft(data) {
    return axios.post('/knowledgeArticleOperate/batchRemoveDraft', data)
}

// 批量恢复 发布状态为已归档的知识文章
export function batchRecoverArchived(data) {
    return axios.post('/knowledgeArticleOperate/batchRecoverArchived', data)
}

// 获取快速操作错误信息
export function getErrorInfo(data) {
    return axios.post('/KnowledgeArticle/getErrorInfo', data)
}

// 解决关闭错误信息
export function operationErrorInfo(data) {
    return axios.post('/KnowledgeArticle/operationErrorInfo', data)
}

// 查询数据分类和数据分类组
export function queryDataCategory(data) {
    return axios.post('dataCategory/queryDataCategory', data)
}

// 保存数据分类选择
export function saveDataCategory(data) {
    return axios.post('/dataCategory/saveDataCategory', data)
}

// 查询已选择数据分类
export function queryDataCategorySelection(data) {
    return axios.post('/dataCategory/queryDataCategorySelection', data)
}

// 知识文章附加到个案
export function addCaseArticle(data) {
    return axios.post('/KnowledgeArticle/addCaseArticle', data)
}

// 取消附加到个案
export function cancelAttachCase(data) {
    return axios.post('/knowledgeArticleOperate/cancelAttachCase', data)
}

// 通过个案id获取文章
export function querCaseArticle(data) {
    return axios.post('/KnowledgeArticle/querCaseArticle', data)
}

// 推荐文章查询
export function recommendedArticleQuery(data) {
    return axios.post('/knowledgeDesignate/recommendedArticleQuery', data)
}

// 是否允许用户从个案中创建文章
export function judgeAddArticleByCase(data) {
    return axios.post('/knowledgeDesignate/judgeAddArticleByCase', data)
}
// 接口1 根据对象获取对象对应的模板列表
export function getObjTemplateList(data) {
    return axios.post('/objTemplate/getObjTemplateList', data)
}
// 获取订单支付状态
export function getPaymentStatus(data) {
    return axios.post('/partnerorder/getPaymentStatus', data)
}
// 获取CCChat后台设置信息
export function getSetupInfo() {
    return axios.post('/ccchat/person/getSetupInfo')
}
// 获取详情页标签
export function GetBrief(data) {
    return axios.post('/objectdetail/getBrief', data)
}
//根据记录id获取标签
export function GetTagsByRecordId(data) {
    return axios.post('/tag/getTagsByRecordId', data)
}
// 获取共享规则列表
export function GetSharegetShareRuleList(data) {
    return axios.post('/share/getShareRuleList', data)
}
//保存pdf到附件
export function SavePdfToFile(data) {
    return axios.post('/quote/savePdfToFile', data)
}
// 获取对象信息
export function getObjectGetObjectInfo(data) {
    return axios.post('/object/getObjectInfo', data)
}
// 判断订单是否为已开具发票状态
export function orderStatus(data) {
    return axios.post('/generateInvoiceNew/orderStatus', data)
}
// 获取支付方式
export function getPayMethod(data) {
    return axios.post('partnerorder/getPayMethod', data)
}
// 发票对象是否展示确定收货按钮
export function getShippingstatus(data) {
    return axios.post('/partnerorder/getShippingstatus', data)
}
// 更新发票的产品运输状态字段
export function updateShippingstatus(data) {
    return axios.post('/partnerorder/updateShippingstatus', data)
}

// 获取对象权限
export function getObjectPermission(data) {
    return axios.post('objectdetail/getObjectPermission', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 获取查找数据
export function getLookupData(data) {
    return axios.post('/lookup/getLookupData', data)
}

// 主从录入表头
export function getMasterInfo(data) {
    return axios.post('/master/getMasterInfo', data)
}

// 添加或更新对象记录
export function save(data) {
    return axios.post('/objectInfo/save', data)
}

// 追随或取消追随,=关注，取消关注
export function setFollow(data) {
    return axios.post('/ccchat/person/setFollow', data)
}
// 是否追随或取消追随,=是否关注，取消关注
export function isFollow(data) {
    return axios.post('/ccchat/person/isFollow', data)
}

// 自定义按钮
export function openCall(data) {
    return axios.post('/openCall/common', data)
}

// 解除记录锁定状态
export function recordUnLocked(data) {
    return axios.post('/objectdetail/recordUnLocked', data)
}

// 潜在客户热度设置信息
export function getLeadHeat(data) {
    return axios.post('leadscore/getLeadHeat', data)
}

// 获取视图列表页面信息
export function getViewListRecently(data) {
    return axios.post('/view/list/getViewListRecently', data)
}

// 获取视图列表表头
export function getViewListHeader(data) {
    return axios.post('/view/list/getViewListHeader', data)
}

// 获取视图列表记录
export function getViewListData(data) {
    return axios.post('/view/list/getViewListAjax', data)
}

// 删除记录
export function del(data) {
    return axios.post('/objectdetail/delete', data)
}

// 获取视图字段设置
export function getFieldSetup(data) {
    return axios.post('/view/getFieldSetup', data)
}

// 保存视图字段设置
export function saveFieldSetup(data) {
    return axios.post('/view/saveFieldSetup', data)
}

// 标记/取消标记为模板
export function modifyProjectTemplate(data) {
    return axios.post('/projectTemplate/modifyProjectTemplate', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 添加市场活动成员
export function addMember(data) {
    return axios.post('/campaign/addMember', data)
}
//价格手册
export function getEnablePriceBook(data) {
    return axios.get('/transaction/getEnablePriceBook', data)
}
//获取业务机会关联的客戶信息
export function getRelatedAccountInfo(data) {
    return axios.post('/transaction/getRelatedAccountInfo', data)
}
// 主从录入编辑页面回显主记录的信息
export function masterRecordDetailInfo(data) {
    return axios.post('/master/masterRecordDetailInfo', data)
}
// 查询所属业务机会的业务机会产品数据
export function relatedOpportunityPdtInfo(data) {
    return axios.post('/master/relatedOpportunityPdtInfo', data)
}
/**
 * 获取用户配置信息
 * @returns 用户配置信息
 */
export function getUserSetup() {
    return axios.get('/user/getUserSetup')
}
// 接口16 保存模板到文件
export function saveTemplatePdf(data) {
    return axios.post('/cpq/template/saveTemplatePdf', data)
}
// 接口17 预览生成的PDF文件
export function getTemplatePdf(data) {
    return axios.post('/cpq/template/getTemplatePdf', data)
}
// 保存模板视图按钮状态
export function saveTemplateViewStatus(data) {
    return axios.post('cpq/template/saveTemplateViewStatus', data)
}
// 查询模板视图按钮状态
export function getTemplateViewStatus(data) {
    return axios.post('/cpq/template/getTemplateViewStatus', data)
}
//获取对象详细信息
export function getDetail(data) {
    return axios.post("/objectdetail/getDetail", data);
}
// 详情页获取模板以及数据信息
export function getTemplateInfo(data) {
    return axios.post('/objectDetail/getTemplateInfo', data)
}

// 客户小组业务机会小组设置页
export function getAccountTeam(data) {
    return axios.post('/opportunityTeam/setup', data)
}
// 客户小组业务机会小组 - 获取相关列表权限数据
export function getRelateObjOption(data) {
    return axios.post('/opportunityTeam/relateObjOption', data)
}

// 获取要展示的接单/拒单按钮
export function oRButton(data) {
    return axios.post('/servicePartner/oRButton', data)
}
// 接单
export function utOrderReceive(data) {
    return axios.post('/servicePartner/utOrderReceive', data)
}
// 拒单
export function utOrderReject(data) {
    return axios.post('/servicePartner/utOrderReject', data)
}
// 拒单原因
export function getRejectReason() {
    return axios.post('/servicePartner/getRejectReason')
}
// 接单规则是否开启
export function getOrdersRule() {
    return axios.post('ordersRule/getOrdersRule')
}
